import z from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";
import { SmartActionSchema } from "./smart-action";

const OpenHoursWeekdaysSchema = z.object({
  mon: z.boolean(),
  tue: z.boolean(),
  wed: z.boolean(),
  thu: z.boolean(),
  fri: z.boolean(),
  sat: z.boolean(),
  sun: z.boolean(),
});

export type OpenHoursWeekdaysType = z.infer<typeof OpenHoursWeekdaysSchema>;
export type KeysOfOpenHoursWeekdaysType = keyof OpenHoursWeekdaysType;

export const OpenHoursSchema = z.object({
  startTime: z.string(),
  endTime: z.string(),
  weekdays: OpenHoursWeekdaysSchema,
});

export type OpenHoursType = z.infer<typeof OpenHoursSchema>;

export const RoundRobinSchema = z.object({
  members: z.array(z.object({ id: z.string(), availabilityId: z.string() })),
  priority: z.enum(["availability", "assignee"]),
  memberSwitching: z.boolean(),
  ownerChange: z.boolean(),
  roster: z.boolean().optional(),
});

export const SchedulerReschedulingOptionsSchema = z.object({
  allowedActions: z.enum(["none", "cancel", "reschedule", "cancel_reschedule"]),
  minimumNotice: z.object({ duration: z.number() }),
  reasonRequired: z.boolean(),
});

export const SchedulerSchema = BaseSchema.extend({
  title: z.string(),
  status: z.enum(["active", "inactive", "deleted"]),
  slug: z.string(),
  timezone: z.string(),
  smartActions: z.array(SmartActionSchema).optional(),
  messagingInvite: z.boolean().optional(),
  icon: z.string(),
  duration: z.number(),
  communication: z.array(
    z.object({
      value: z.string(),
      memberId: z.string().optional(),
      roundRobinMemberIds: z.array(z.string()).optional(),
      extra: z.string().optional(),
    })
  ),
  availabilityId: z.string().nullable(),
  description: z.string().optional(),
  sharedWith: z.array(z.string()).optional(),
  bufferTimeAfter: z.number().nullable().optional(),
  bufferTimeBefore: z.number().nullable().optional(),
  maximumLeadTimeDays: z.number().nullable().optional(),
  mimimumLeadTimeDays: z.number().nullable().optional(),
  openHours: z.array(OpenHoursSchema).optional(),
  productId: z.string().optional(),
  publicOrder: z.number().nullable().optional(),
  secret: z.boolean().optional(),
  startTimeInterval: z.string().optional(),
  minimumLeadTimeHours: z.number().optional(),
  maximumDayUsage: z.number().optional(),
  maximumWeekUsage: z.number().optional(),
  roundRobin: RoundRobinSchema.optional(),
  readCalendars: z.any(),
  reminders: z.array(z.any()).optional(),
  writeCalendar: z.any(),
  reschedulingOptions: SchedulerReschedulingOptionsSchema.optional(),
});

export type SchedulerType = z.infer<typeof SchedulerSchema>;

export type SchedulerReschedulingOptionsType = z.infer<
  typeof SchedulerReschedulingOptionsSchema
>;

export const schemaProperties: SchemaProperties = {
  collectionName: "schedulers",
  deleteMode: "soft",
};
