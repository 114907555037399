import { z } from "zod";

import { SmartActionSchema } from "@lib/data/schemas/smart-action";
import { ZodDateAsString } from "@lib/utils/zod-schemas";

import { getSchemaDefinition } from "../common";

const tags = ["smart-actions"];

const smartActionCommonPostPayloadSchema = z.object({
  smartAction: SmartActionSchema.extend({
    id: z.string().optional(),
    when: ZodDateAsString,
    createdAt: ZodDateAsString,
    updatedAt: ZodDateAsString,
  }),
});
const smartActionCommonPostResponseSchema = z.object({
  ok: z.boolean(),
  statusChangedByEndpoint: z.boolean().optional().default(false),
});

export const billScheduledInvoice = getSchemaDefinition(
  "/api/v1/smart-actions/bill-scheduled-invoice",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Updates an stripe invoice to be charged",
    tags,
  },
  smartActionCommonPostResponseSchema
);

/* /api/{apiVersion}/smart-actions/send-invoice-reminder-coach                                        */

export const postInvoiceReminderCoach = getSchemaDefinition(
  "/api/{apiVersion}/smart-actions/send-invoice-reminder-coach",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Sends unpaid invoice reminders to coaches",
    tags,
  },
  smartActionCommonPostResponseSchema
);

/*************************************************************************************************/

/* /api/{apiVersion}/smart-actions/send-invoice-reminder-coach                                        */

export const postInvoiceReminderClient = getSchemaDefinition(
  "/api/{apiVersion}/smart-actions/send-invoice-reminder-client",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Sends unpaid invoice reminders to clients",
    tags,
  },
  smartActionCommonPostResponseSchema
);

/*************************************************************************************************/

export const deleteSmartActionsByActionResourceId = getSchemaDefinition(
  "/api/{apiVersion}/users/{userId}/smart-action-resource/{smartActionResourceId}",
  "delete",
  {
    path: z.object({ userId: z.string(), smartActionResourceId: z.string() }),
    query: z.object({
      preserveTemplates: z.string().optional(),
    }),
  },
  {
    description:
      "Remove all pending scheduled smart actions based on the `action.resourceId`",
    tags,
  },
  z.object({})
);

export const postSendTrialWillEndReminder = getSchemaDefinition(
  "/api/{apiVersion}/smart-actions/send-trial-will-end-reminder",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Sends trial will end reminders to coaches",
    tags,
  },
  z.object({
    status: z.string(),
  })
);

export const todoAssignmentReminderEndpointDefinition = getSchemaDefinition(
  "/api/v1/smart-actions/todo-assignment-reminder",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Sends todo assignment reminders to coaches and members",
    tags,
  },
  smartActionCommonPostResponseSchema
);

export const todoDueDateReminderEndpointDefinition = getSchemaDefinition(
  "/api/v1/smart-actions/todo-due-date-reminder",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Sends todo due date reminder to coaches and members",
    tags,
  },
  smartActionCommonPostResponseSchema
);

export const postSendFormWithSignedContract = getSchemaDefinition(
  "/api/v1/smart-actions/send-form-with-signed-contract",
  "post",
  {
    body: smartActionCommonPostPayloadSchema,
  },
  {
    description: "Sends form with signed contract",
    tags,
  },
  smartActionCommonPostResponseSchema
);
