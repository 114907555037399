import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

export const SmartActionStatusEnum = z.enum([
  "pending",
  "completed",
  "processing",
  "paused",
  "deleted",
  "failed",
]);
export type SmartActionStatusEnumType = z.infer<typeof SmartActionStatusEnum>;

export const SmartActionTimingEnum = z.enum([
  "immediately",
  "before",
  "after",
  "onDate",
  "count",
]);
export type SmartActionTimingEnumType = z.infer<typeof SmartActionTimingEnum>;

export const SmartActionTriggerEnum = z.enum([
  "appointment-is-booked",
  "appointment-starts",
  "appointment-ends",
  "appointment-outcome-updated",
  "client-birthday",
  "form-is-submitted",
  "payment-is-completed",
  "package-is-booked",
  "package-is-assigned",
  "package-sessions-completed",
  "product-is-purchased",
  "invoice-send-date",
  "package-instance-is-created",
  "package-instance-recurring-due",
  "package-instance-ended",
  "event-is-booked",
  "event-starts",
  "event-ends",
  "invoice-is-due",
  "subscription-trial-will-end",
  "todo-assignment",
  "todo-due-date",
  "send-form-reminder",
  "package-tracking-status-changed",
  "package-is-at-risk",
  "package-is-off-track",
]);
export type SmartActionTriggerEnumType = z.infer<typeof SmartActionTriggerEnum>;

export const SmartActionEventTypeEnum = z.enum([
  "appointment",
  "form",
  "package",
  "invoice",
  "packageInstance",
  "subscriptions",
  "todo",
]);
export type SmartActionEventTypeEnumType = z.infer<
  typeof SmartActionEventTypeEnum
>;

export const SmartActionEventSchema = z.object({
  timming: SmartActionTimingEnum,
  trigger: SmartActionTriggerEnum,
  resourceId: z.string(),
  type: SmartActionEventTypeEnum,
  settings: z
    .object({
      acceptedOutcomes: z.string().array().optional(),
      appointmentCount: z.number().optional(),
    })
    .optional(),
});
export type SmartActionEventType = z.infer<typeof SmartActionEventSchema>;

export const SmartActionActionTypeEnum = z.enum([
  "add-label",
  "send-form",
  "send-form-with-signed-contract",
  "show-form",
  "send-reminder",
  "send-birthday-reminder",
  "send-library-item",
  "send-invoice",
  "bill-scheduled-invoice",
  "send-invoice-reminder-coach",
  "send-invoice-reminder-client",
  "send-trial-will-end-reminder",
  "todo-assignment-reminder",
  "todo-due-date-reminder",
  "todo-week-reminder",
  "recurring-package-instance",
  "complete-package-instance",
  "create-billable-request",
  "rollover-pending-transactions",
  "send-form-reminder",
  "send-package-tracking-email",
  "check-package-tracking-status",
]);
export type SmartActionActionTypeEnumType = z.infer<
  typeof SmartActionActionTypeEnum
>;

export const SmartActionActionArtifactEnum = z.enum([
  "labels",
  "forms",
  "files",
  "folders",
  "links",
  "invoices",
  "packages",
  "packageInstances",
  "subscriptions",
  "accounts",
  "clients",
]);
export type SmartActionActionArtifactEnumType = z.infer<
  typeof SmartActionActionArtifactEnum
>;

export const SmartActionActionMethodEnum = z.enum([
  "to-client-record",
  "via-email",
  "during-book-flow",
  "via-sms",
  "via-email-via-sms",
  "via-sms-via-email",
]);
export type SmartActionActionMethodEnumType = z.infer<
  typeof SmartActionActionMethodEnum
>;

export const SmartActionActionSchema = z.object({
  type: SmartActionActionTypeEnum,
  artifactType: SmartActionActionArtifactEnum.optional(),
  resourceId: z.string().optional(),
  method: SmartActionActionMethodEnum.optional(),
  settings: z
    .object({
      billingRateId: z.string().optional(),
      duration: z.number().optional(),
      adminOnly: z.boolean().optional(),
    })
    .optional(),
});
export type SmartActionActionType = z.infer<typeof SmartActionActionSchema>;

export const SmartActionSchema = BaseSchema.extend({
  clientId: z.string().optional(),
  coachId: z.string(),
  status: SmartActionStatusEnum,
  when: z.date(),
  event: SmartActionEventSchema,
  action: SmartActionActionSchema,
  apiPath: z.string().optional(),
  useApi: z.boolean().optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
  silent: z.boolean().optional(),
});

export type SmartActionType = z.infer<typeof SmartActionSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "smartActions",
  deleteMode: "hard",
};
