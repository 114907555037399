import { useMemo, useState } from "react";
import { BaseAPI, Configuration, ConfigurationParameters } from "@practice/sdk";
import { parseCookies } from "nookies";

import { getCurrentAPIServerURI } from "@lib/utils";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import useLogger from "./use-logger";
import { useRequestIdGenerator } from "./use-request-id-generator";
import useSnackbar from "./use-snackbar";

export type ApiConstructor<T extends BaseAPI> = {
  new (configuration: Configuration): T;
};

export const useSDKApi = <T extends BaseAPI>(
  ApiClass: ApiConstructor<T>,
  configuration: ConfigurationParameters = {}
): T => {
  const cookies = parseCookies();

  const api = useMemo(() => {
    const configurationParams: ConfigurationParameters = {
      basePath: getCurrentAPIServerURI(),
      accessToken: cookies?.firebase_token,
      ...configuration,
    };
    return new ApiClass(new Configuration(configurationParams));
  }, [ApiClass.name, JSON.stringify(configuration), cookies?.firebase_token]);
  return api;
};

export const useSDKApiExecute = function <
  T extends BaseAPI,
  M extends keyof T,
  Z extends T[M] extends (...args: any[]) => any ? T[M] : never,
>(
  endpoint: ApiConstructor<T>,
  options: {
    method: M;
    onError?: (e: any) => void;
    onSuccess?: (result: Awaited<ReturnType<Z>>, data: any) => void;
    errorLogMessage: string;
    showErrorSnackbar?: boolean;
    keyOrigin: string;
  }
) {
  const {
    onError,
    onSuccess,
    errorLogMessage,
    showErrorSnackbar,
    keyOrigin,
    method,
  } = options;
  const generateRequestId = useRequestIdGenerator(keyOrigin);
  const api = useSDKApi(endpoint);
  const [loading, setLoading] = useState<any>(false);
  const snackbar = useSnackbar();
  const { logger } = useLogger(keyOrigin);

  const execute = async (
    props: Omit<Parameters<Z>[0], "xRequestId">,
    loadingHelperData?: any
  ): Promise<ReturnType<Z> | undefined> => {
    const requestId = generateRequestId();
    try {
      logger.info({ ...props, requestId }, `Executing ${method as string}`);
      setLoading(loadingHelperData || true);
      const result = await (api[method] as Z)({
        xRequestId: requestId,
        ...props,
      });

      if (onSuccess) onSuccess(result, loadingHelperData);
      return result;
    } catch (e: Error | any) {
      const errorMessage = await catchErrorSDK(e, errorLogMessage);
      logger.error(
        {
          error: e,
          errorMessage,
          message: e.message,
          stack: e.stack,
          cause: e.cause,
          props,
        },
        errorLogMessage
      );
      if (showErrorSnackbar)
        snackbar.showWarning(errorMessage || errorLogMessage);
      if (onError) onError(e);
    } finally {
      logger.info({ ...props, requestId }, `Executed ${method as string}`);
      setLoading(false);
    }
  };

  return { execute, loading };
};
